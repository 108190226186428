import { useState } from 'react'

import { useIsomorphicLayoutEffect } from 'usehooks-ts'
export enum OS_OPTIONS {
  UNDETERMINED = 'undetermined',
  MACOS = 'macos',
  IOS = 'ios',
  WINDOWS = 'windows',
  ANDROID = 'android',
  LINUX = 'linux',
}

export type OS =
  | 'undetermined'
  | 'macos'
  | 'ios'
  | 'windows'
  | 'android'
  | 'linux'

function getOS(): OS {
  if (typeof window === 'undefined') {
    return OS_OPTIONS.UNDETERMINED
  }

  const { userAgent } = window.navigator
  const macosPlatforms = /(Macintosh)|(MacIntel)|(MacPPC)|(Mac68K)/i
  const windowsPlatforms = /(Win32)|(Win64)|(Windows)|(WinCE)/i
  const iosPlatforms = /(iPhone)|(iPad)|(iPod)/i

  if (macosPlatforms.test(userAgent)) {
    return OS_OPTIONS.MACOS
  }
  if (iosPlatforms.test(userAgent)) {
    return OS_OPTIONS.IOS
  }
  if (windowsPlatforms.test(userAgent)) {
    return OS_OPTIONS.WINDOWS
  }
  if (/Android/i.test(userAgent)) {
    return OS_OPTIONS.ANDROID
  }
  if (/Linux/i.test(userAgent)) {
    return OS_OPTIONS.LINUX
  }

  return OS_OPTIONS.UNDETERMINED
}

interface UseOsOptions {
  getValueInEffect: boolean
}

export function useOs(options: UseOsOptions = { getValueInEffect: true }): OS {
  const [value, setValue] = useState<OS>(
    options.getValueInEffect ? 'undetermined' : getOS(),
  )

  useIsomorphicLayoutEffect(() => {
    if (options.getValueInEffect) {
      setValue(getOS)
    }
  }, [])

  return value
}
